import { gql } from '@apollo/client';

export const DEPARTMENT_ABOUT_QUERY = gql`
  query DepartmentAbout($input: DepartmentInput!) {
    department(input: $input) {
      alias
      brandId
      email
      name
      texts {
        ... on DepartmentTextsDefault {
          about
        }
      }
    }
  }
`;
