import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';

import { useQuery } from '@apollo/client';
import NoMatch from '../../404Old';
import { DEPARTMENT_ABOUT_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/about';
import { client } from '../../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../../__config__/env';
import {
  DepartmentAboutQuery,
  DepartmentAboutQueryVariables
} from '../../../../__types__/generated/cluster';
interface IRoute {
  urltag: string;
}

const Description: React.FC<RouteComponentProps<IRoute>> = ({ match }) => {
  const { data, error, loading } = useQuery<
    DepartmentAboutQuery,
    DepartmentAboutQueryVariables
  >(DEPARTMENT_ABOUT_QUERY, {
    fetchPolicy: 'network-only',
    client,
    variables: {
      input: {
        brandId: ENV_CONFIG?.BRAND_ID,
        alias: match.params.urltag
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading && (data?.department?.alias?.length ? true : false)
  });

  if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
    return <NoMatch />;
  }

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      <Section style={fadeIn}>
        <Heading tag="h1" center={true}>
          PrivatMegleren {data?.department?.name}
        </Heading>
        {data?.department?.texts?.about ? (
          <Paragraph center={true}>
            <span
              dangerouslySetInnerHTML={{
                __html: data?.department?.texts?.about
              }}
              style={{ color: 'white' }}
            />
          </Paragraph>
        ) : null}
        <Heading tag="h3" center={true}>
          Om PrivatMegleren
        </Heading>
        <Paragraph center={true}>
          PrivatMegleren har som mål å sørge for at du som eiendomsselger oppnår
          høyest mulig pris. Veien frem til det beste budet handler om å
          tilrettelegge i forkant slik at flest mulig blir oppmerksom på akkurat
          din eiendom.
        </Paragraph>
        <Paragraph center={true}>
          PrivatMegleren er landsdekkende med sterkt fokus på å ha de beste
          meglerne i bransjen og sørge for at de har de mest moderne, digitale
          verktøyene på markedet til enhver tid. Konseptet er å lete frem det
          unike ved enhver eiendom – det kalles for det Gylne Kvadrat - og det
          er nettopp det Gylne Kvadrat som må synliggjøres for at en eiendom
          skal kunne presenteres på beste måte for potensielle kjøpere.
          PrivatMegleren er Nordeas eiendomsmeglerkjede. Det betyr for deg som
          kunde at våre Nordea bistår deg gjerne i prosessen med finansiell
          rådgivning og finansieringsbevis i forbindelse med kjøp og salg av
          eiendom, fritidseiendom eller nyoppført eiendom/prosjekt.
        </Paragraph>
      </Section>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </Container>
  );
};

export default Description;
